import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Breadcrumb from "../../components/breadcrumb"
import HorairesHeader from "../../components/horairesHeader"

const days = {
  monday: "Lundi",
  tuesday: "Mardi",
  wednesday: "Mercredi",
  thursday: "Jeudi",
  friday: "Vendredi",
  saturday: "Samedi",
}

const TestsDeCodePage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Tests de code" breadcrumb={[
        { url: "les-horaires", name: "Les horaires"},
        { url: "tests-de-code", name: "Tests de code"},
      ]} />
      <Breadcrumb paths={[
        { url: "/les-horaires/", name: "Les horaires" },
        { url: null, name: "Tests de code" }
      ]} />

      <HorairesHeader active={1} />

      <table className="border-collapse">
        {Object.keys(days).map(day => (
          <tr className="tr">
          <td className="td gradient-dark">{days[day]}</td>
            {data.strapiHorairesDeCode[day].map(el => (
              <td className="td">{el.time.substring(0,5)}</td>
            ))}
          </tr>
        ))}
      </table>
    </Layout>
  )
}

export default TestsDeCodePage

export const pageQuery = graphql`
  query TestsCodeQuery {
    strapiHorairesDeCode {
      monday {
        time
      }
      tuesday {
        time
      }
      wednesday {
        time
      }
      thursday {
        time
      }
      friday {
        time
      }
      saturday {
        time
      }
    }
  }
`
